var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [_c("logo", { attrs: { public: "" } })],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-logo flex lg:hidden justify-items-center justify-center items-center mb-2 pt-4",
                          },
                          [_c("logo", { attrs: { public: "" } })],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-4 mb-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-card__title mb-4 grid justify-items-center",
                              },
                              [
                                _c("h4", { staticClass: "mb-3" }, [
                                  _vm._v(_vm._s(_vm.$t("entrar-na-sua-conta"))),
                                ]),
                                _c("p", { staticClass: "mb-3" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("bem-vindo")) + " "
                                  ),
                                ]),
                                _vm.s_email
                                  ? _c(
                                      "p",
                                      { staticClass: "danger-color mb-4" },
                                      [
                                        _c("b", [
                                          _vm._v("Atenção, Login Simulado:"),
                                        ]),
                                        _vm._v(" " + _vm._s(_vm.s_email)),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email|min:3",
                                  expression: "'required|email|min:3'",
                                },
                              ],
                              staticClass: "w-full no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: "email",
                                icon: "icon icon-user",
                                "icon-pack": "feather",
                                "label-placeholder": "Email",
                                placeholder: _vm.$t("email"),
                                "data-vv-as": "Email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:6",
                                  expression: "'required|min:6'",
                                },
                              ],
                              staticClass: "w-full mt-8 no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                type: "password",
                                name: "password",
                                icon: "icon icon-lock",
                                "icon-pack": "feather",
                                "label-placeholder": "Senha",
                                placeholder: _vm.$t("password"),
                                "data-vv-as": "Senha",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.enterPressed.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("password"))),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-between my-5",
                              },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    staticClass: "mb-3",
                                    model: {
                                      value: _vm.checkbox_remember_me,
                                      callback: function ($$v) {
                                        _vm.checkbox_remember_me = $$v
                                      },
                                      expression: "checkbox_remember_me",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("lembre-se-de-mim")))]
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/forgot-password" } },
                                  [_vm._v(_vm._s(_vm.$t("esqueceu-a-senha")))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-button",
                              {
                                attrs: { type: "border" },
                                on: { click: _vm.registerUser },
                              },
                              [_vm._v(_vm._s(_vm.$t("criar-conta")))]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-right",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.login },
                              },
                              [_vm._v(_vm._s(_vm.$t("entrar")))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "social-login grid justify-items-center",
                              },
                              [
                                _c("vs-divider", [
                                  _vm._v(_vm._s(_vm.$t("ou-entre-com"))),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "social-login-buttons flex flex-wrap items-center mt-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top",
                                            value: {
                                              content:
                                                "Faça login ou crie sua conta usando a autenticação do Facebook.",
                                              delay: { show: 1000, hide: 100 },
                                            },
                                            expression:
                                              "{\n                      content: 'Faça login ou crie sua conta usando a autenticação do Facebook.',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                            modifiers: { top: true },
                                          },
                                        ],
                                        staticClass:
                                          "bg-facebook pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4",
                                        on: { click: _vm.loginWithFacebook },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "text-white h-4 w-4 svg-inline--fa fa-facebook-f fa-w-9",
                                            attrs: {
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              "data-prefix": "fab",
                                              "data-icon": "facebook-f",
                                              role: "img",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 264 512",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "currentColor",
                                                d: "M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "ml-2 button-text" },
                                          [_vm._v(_vm._s(_vm.$t("facebook")))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top",
                                            value: {
                                              content:
                                                "Faça login ou crie sua conta usando a autenticação do Google.",
                                              delay: { show: 1000, hide: 100 },
                                            },
                                            expression:
                                              "{\n                      content: 'Faça login ou crie sua conta usando a autenticação do Google.',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                            modifiers: { top: true },
                                          },
                                        ],
                                        staticClass:
                                          "bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4",
                                        on: { click: _vm.loginWithGoogle },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "text-white h-4 w-4 svg-inline--fa fa-google fa-w-16",
                                            attrs: {
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              "data-prefix": "fab",
                                              "data-icon": "google",
                                              role: "img",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 488 512",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "currentColor",
                                                d: "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "ml-2 button-text" },
                                          [_vm._v(_vm._s(_vm.$t("google")))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }