<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <logo public/>
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="vx-logo flex lg:hidden justify-items-center justify-center items-center mb-2 pt-4">
                <logo public/>
              </div>
              <div class="p-4 mb-3">
                <div class="vx-card__title mb-4 grid justify-items-center">
                  <h4 class="mb-3">{{ $t('entrar-na-sua-conta') }}</h4>
                  <p class="mb-3"> {{ $t('bem-vindo') }} </p>
                  
                  <p v-if="s_email" class="danger-color mb-4"><b>Atenção, Login Simulado:</b> {{s_email}}</p>
                </div>

                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full no-icon-border"
                  :placeholder="$t('email')"
                  data-vv-as="Email"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="Senha"
                  :placeholder="$t('password')"
                  data-vv-as="Senha"
                  v-model="password"
                  class="w-full mt-8 no-icon-border"
                  @keyup.enter="enterPressed"
                />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <div class="flex flex-wrap justify-between my-5">
                  <vs-checkbox v-model="checkbox_remember_me" class="mb-3">{{ $t('lembre-se-de-mim') }}</vs-checkbox>
                  <router-link to="/forgot-password">{{ $t('esqueceu-a-senha') }}</router-link>
                </div>
                <vs-button type="border" @click="registerUser">{{ $t('criar-conta') }}</vs-button>
                <vs-button class="float-right" :disabled="!validateForm" @click="login">{{ $t('entrar') }}</vs-button>

                <div class="social-login grid justify-items-center">
                  <vs-divider>{{ $t('ou-entre-com') }}</vs-divider>
                  <div class="social-login-buttons flex flex-wrap items-center mt-4">

                    <!-- FACEBOOK -->
                    <div
                      class="bg-facebook pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4"
                      @click="loginWithFacebook"
                      v-tooltip.top="{
                        content: 'Faça login ou crie sua conta usando a autenticação do Facebook.',
                        delay: { show: 1000, hide: 100 }
                      }">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="text-white h-4 w-4 svg-inline--fa fa-facebook-f fa-w-9" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path fill="currentColor" d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"></path></svg>
                      <span class="ml-2 button-text">{{ $t('facebook') }}</span>
                    </div>

                    <!-- GOOGLE -->
                    <div
                      class="bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4"
                      @click="loginWithGoogle"
                      v-tooltip.top="{
                        content: 'Faça login ou crie sua conta usando a autenticação do Google.',
                        delay: { show: 1000, hide: 100 }
                      }">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                      <span class="ml-2 button-text">{{ $t('google') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import { popupCenter } from '@/util/Util'
import Logo from '@/layouts/components/Logo.vue'

export default {
  components: {
    Logo
  },
  props: {
    s_email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      smallScreen: false
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  methods: {
    login() {
      // Loading
      this.$vs.loading()

      const payload = {
        updateUsername: true,
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          s_email: this.s_email,
          password: this.password
        },
        vs: this.$vs,
        closeAnimation: this.$vs.loading.close,
        acl: this.$acl
      }

      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading.close()
        this.notifyError(this.$vs, this.$t('nao-e-possivel-utilizar-mais-que-um-usua'), 1500)
        setTimeout(() => this.$router.go(), 2000)
      } else {
        localStorage.removeItem('userInfo')
        this.$store.dispatch('auth/loginAttempt', payload)
      }

    },

    loginAuth0() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn()
        return false
      }
      this.$auth.login({ target: this.$route.query.to })
    },

    // Google login
    loginWithGoogle() {
      this.clearFields()
      popupCenter(`${process.env.VUE_APP_API_BASE_URL}/api/v1/auth/redirect/google`, 'google.com', '500', '600', {toolbar:1, resizable:1, location:1, menubar:1, status:1})
    },

    // Facebook login
    loginWithFacebook() {
      this.clearFields()
      popupCenter(`${process.env.VUE_APP_API_BASE_URL}/api/v1/auth/redirect/facebook`, 'facebook.com', '500', '600', {toolbar:1, resizable:1, location:1, menubar:1, status:1})
    },

    notifyAlreadyLogedIn() {
      this.$vs.notify({
        title: this.$t('login-attempt'),
        text: this.$t('you-are-already-logged-in'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
    },
    registerUser() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn()
        return false
      }
      this.$router.push('/register')
    },
    enterPressed() {
      this.login()
    },
    loginListener(e) {
      const data = e.data.data
      if (data && ('user' in data)) {
        const user = JSON.parse(data.user)
        const token = data.token
        const provider = data.provider
        const payload = { vs: this.$vs, user: user, token: token, provider: provider, acl: this.$acl }
        this.$store.dispatch('auth/socialLogin', payload)
      }
    },
    clearFields() {
      this.email = ''
      this.password = ''
    }
  },
  created() {
    this.smallScreen = this.isSmallScreen()
  },
  mounted() {
    window.addEventListener('message', this.loginListener, false)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.loginListener, false)
  }
}
</script>

<style lang="scss">
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
    .button-text {
      vertical-align: text-bottom;
      color: white;
    }
  }
}
</style>
